footer{
    z-index : -10;
    padding-top: 50px;
    padding-bottom: 40px;
    background-image: linear-gradient(to top, #525252, #323232, #494949, #343434);
    position: relative;
    @media (max-width:991px) {
        padding-top: 25px;
        padding-bottom: 20px;
    }
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-image: linear-gradient(to left, #ff6e02, #ffea00, #ff6d00);
        @media (max-width:991px) {
            height: 3px;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1155px;
        }
       
    }
    .f-obx{
        display: flex;
        justify-content: space-between;
        @media (max-width:991px) {
            display: block;
        }
        .l{
            @media (max-width:991px) {
                width: 95%;
                margin: 0 auto;
                margin-bottom: 20px;
            }
            
           .f-logo{
               display: flex;
               margin-bottom: 25px;
               align-items: center;
               @media (max-width:991px) {
                   margin-bottom: 15px;
               }
               img{
                   width: 39px;
                   height: 39px;
                   margin-right: 10px;
                   @media (max-width:991px) {
                       margin-right: 6px;
                   }
               }
                @include fontset(20,2,4,500,#ffffff);
                @media (max-width:991px) {
                    font-size: pxToEm(16);
                    letter-spacing: 3px;
                    line-height: 1.6;
                }
                @media (max-width:360px) {
                    font-size: pxToEm(14);
                    letter-spacing: 2px;
                }
           }   
           .adrss{
               display: flex;
               margin-bottom: 30px;
               @media (max-width:991px) {
                   align-items: center;
                   margin-bottom: 20px;
               }
               .img-bx{
                   width: 39px;
                   position: relative;
                   margin-right: 10px;
                   @media (max-width:991px) {
                    margin-right: 6px;
                    height: 27px;
                   }
               }
               img{
                  
                   width: 25px;
                   height: 27px;
                   position: absolute;
                   left: 50%;
                   transform: translateX(-50%);
               }
                @include fontset(16,1.5,.8,400,#c9c9c9);
                @media (max-width:991px) {
                    font-size: pxToEm(14);
                }
           }
           .tel{
                display: flex;
                margin-bottom: 30px;
                @media (max-width:991px) {
                    margin-bottom: 18px;
                }
                
                .img-bx{
                    width: 39px;
                    position: relative;
                    margin-right: 10px;
                    @media (max-width:991px) {
                        margin-right: 6px;
                       }
                    img{
                        width: 26px;
                        height: 24px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                .da-bx{
                    div{
                        @include fontset(16,1.5,.8,400,#c9c9c9);
                        @media (max-width:991px) {
                            font-size: pxToEm(14);
                        }
                    }
                    a{
                        @include fontset(16,1.5,.8,400,#c9c9c9);
                        @media (max-width:991px) {
                            font-size: pxToEm(14);
                        }
                    }
                    .tai,.sou{
                        display: flex;
                        @media (max-width:360px) {
                            display: block;
                        }
                        >div{
                            margin-right: 7px;
                        }
                    }
                }
           }
           .time{
            display: flex;
                .img-bx{
                    width: 39px;
                    position: relative;
                    margin-right: 10px;
                    @media (max-width:991px) {
                        margin-right: 6px;
                       }
                    img{
                        width: 31px;
                        height: 24px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        @media (max-width:991px) {
                            width: 26px;
                        }
                    }
                
                }
                .da-bx{
                    @include fontset(16,1.5,.8,400,#c9c9c9);
                    @media (max-width:991px) {
                        font-size: pxToEm(14);
                    }
                }
           }
        }
        .r{
            width: 62.2%;
            @media (max-width:991px) {
                width: 100%;
            }
            iframe{
                width: 100%;
                height: 300px;
                @media (max-width:991px) {
                    height: 315px;
                }
            }
        }
    }
    .b-bx{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        border-top: solid 2.7px #5a5a5a;
        margin-top: 30px;
        position: relative;
        @media (max-width:991px) {
            display: block;
            border-top: solid 1px #5a5a5a;
            padding-top: 25px;
        }
        &::before{
            position: absolute;
            content: '';
            top: 1px;
            width: 100%;
            height: 1px;
            background-color:#1b1b1b ;
        }
        .line-bx{
            display: flex;
            @media (max-width:991px) {
                width: 88%;
                margin: 0 auto;
                margin-bottom: 40px;
            }
            @media (max-width:360px) {
                width: 100%;
            }
            .lineid{
                display: block;
                padding-left: 55px;
                position: relative;
                &:first-child{
                    margin-right: 80px;
                    @media (max-width:1199px) {
                        margin-right: 30px;
                    }
                    @media (max-width:345px) {
                        margin-right: 8px;
                    }
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    background: url(../images/footer_LINEicon.png) no-repeat center center / cover;
                    width: 41px;
                    height: 41px;
                    @media (max-width:991px) {
                        width: 36px;
                        height: 36px;
                    }
                }
                div{
                    @include fontset(14,1.28,.3,500,#ffffff);
                    @media (max-width:991px) {
                        font-size: pxToEm(13);
                    }
                }
            }
        }
        .wechat-bx{
            display: flex;
            @media (max-width:991px) {
                display: block;
            }
            .wechat{
                padding-left: 55px;
                position: relative;
                display: flex;
                align-items: center;
                @include fontset(14,1.28,.3,500,#ffffff);
                @media (max-width:991px) {
                    font-size: pxToEm(13);
                    margin: 0 auto;
                     width: 85%;
                     padding-left: 49px;
                }
                &:first-child{
                    margin-right: 72px;
                    @media (max-width:1199px) {
                        margin-right: 25px;
                    }
                    @media (max-width:991px) {
                        margin: 0 auto;
                        margin-bottom: 32px;
                    }
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url(../images/footer_Wechaticon.png) no-repeat center center / cover;
                    width: 41px;
                    height: 41px;
                    @media (max-width:991px) {
                        width: 36px;
                        height: 36px;
                    }
                }
                img{
                    width: 107px;
                    height: 107px;
                    margin-left: 15px;
                    @media (max-width:991px) {
                        width: 90px;
                        height: 90px;
                    }
                }
                span{
                    @media (max-width:991px) {
                        min-width: 95px;
                    }
                }
            }
        }
    }
}