.indexPage{
    .banner{
        width: 100vw;
        height: 556px;
        position: relative;
        @media (max-width:767px) {
            height: 97.7%;
        }
        .bgcover{
            background-size: cover !important;
            padding-bottom: 556px;
            @media (max-width:767px) {
                padding-bottom: 97.7%;
            }
        }
    }
    .item1{
        padding-top: 65px;
        padding-bottom: 85px;
        background: url(../images/a_bg_pc.jpg) center center;
        @media (max-width:767px) {
            padding-top: 20px;
            padding-bottom: 40px;
        }
        .des-bx{
            max-width: 615px;
            width: 100%;
            @include fontset(20,2,1,300);
            margin: 0 auto;
            text-align: center;
            margin-top: 15px;
            @media (max-width:767px) {
                font-size: pxToEm(15);
                margin-top: 8px;
                line-height: 1.7;
            }
        }
    }
    .item2{
        padding-top: 80px;
        padding-bottom: 75px;
        @media (max-width:767px) {
            padding-top: 23px;
            padding-bottom: 35px;
        }
        .sh-titlebx{
            margin-bottom: 65px;
            @media (max-width:767px) {
                margin-bottom: 21px;
            }
        }
        .it2-bx{
            ul{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @media (max-width:767px) {
                    display: block;
                }
                li{
                    width: 48%;
                    display: flex;
                    margin-bottom: 95px;
                    @media (max-width:767px) {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &:nth-last-child(2){
                        margin-bottom: 0;
                        @media (max-width:767px) {
                            margin-bottom: 25px;
                        }
                    }
                    .l{

                    }
                    .r{
                        width: 78%;
                        margin-left: 6%;
                        @media (max-width:767px) {
                            width: 100%;
                            margin-left: 4%;
                        }
                        .ti{
                            @include fontset(20,1,2,400,#3d3d3d);
                            padding-bottom: 19px;
                            margin-bottom: 12px;
                            position: relative;
                            @media (max-width:767px) {
                                font-size: pxToEm(20);
                                padding-bottom: 14px;
                                margin-bottom: 8px;
                               line-height: 1.35;
                            }
                            &::before{
                                position: absolute;
                                content: '';
                                bottom: 0;
                                left: 0;
                                width: 80px;
                                height: 3px;
                                background-image: linear-gradient(to left, #ff6e02, #ffcc00, #ff4e00);
                                @media (max-width:767px) {
                                    width: 55px;
                                    height: 2px;
                                }
                            }
                        }
                        .des{
                            @include fontset(14,1.7,.35,400,#303030);
                            @media (max-width:767px) {
                                letter-spacing: .1px;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }
    .item3{
        padding-top: 70px;
        padding-bottom: 80px;
        background: url(../images/c_bg_pc.jpg) no-repeat center center / cover;
        @media (max-width:991px) {
            padding-top: 30px;
            padding-bottom: 20px;
            background: url(../images/c_bg_mb.jpg)no-repeat center center / cover;;
        }
        ul{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-top: 60px;
            @media (max-width:991px) {
                margin-top: 20px;
                justify-content: space-around;
            }
            li{
                width: 12.9%;
                @media (max-width:1199px) {
                    width: 16%;
                }
                @media (max-width:991px) {
                    width: 38%;
                    margin-bottom: 20px;
                    &:last-child{
                        width: 38%;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                }
                &:not(:first-child){
                    .ti{
                        &::after{
                            position: absolute;
                            content: '';
                            left: -58px;
                            top: 3px;
                            border-left: 22px solid #dcdcdc;
                            border-top: 11px dashed transparent;
                            border-bottom: 11px dashed transparent;
                            @media (max-width:1199px) {
                                left: -32px;
                            }
                            @media (max-width:991px) {
                                left: -9px;
                                border-left: 16px solid #dcdcdc;
                                border-top: 8px dashed transparent;
                                border-bottom: 8px dashed transparent;
                            }
                        }
                    }
                }
                .img-bx{
                    width: 80px;
                    height: 80px;
                    position: relative;
                    margin: 0 auto;
                    margin-bottom: 15px;
                    @media (max-width:767px) {
                        width: 47px;
                        height: 47px;
                    }
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                .ti{
                    @include fontset(22,1,.55,400,#303030);
                    padding-bottom: 14px;
                    position: relative;
                    margin-bottom: 12px;
                    text-align: center;
                    @media (max-width:991px) {
                        font-size: pxToEm(18);
                        padding-bottom: 9px;
                        margin-bottom: 7px;
                        line-height: 1.35;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-image: linear-gradient(to left, #ff6e02, #ffcc00, #ff4e00);
                    }
                }
                .des{
                    @include fontset(15,1.73,.75,400,#303030);
                    @media (max-width:991px) {
                        font-size: pxToEm(13);
                        line-height: 1.4;
                    }
                }
            }
        }
    }
}


