/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 20px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1157px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 30px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 30px;
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            border-bottom: 1px solid #dbdbdb;
            font-size: pxToEm(14);
            color: #000000;
            @media (max-width:767px) {
                padding:7px 0 3px;
                margin:0 20px 0 0; 
            }
            @media (min-width:768px) {
                margin:0 10px; 
                padding:7px 0;
            }
          
        }
        li.active a{
            border-color:black;
            color:black;
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: -18px;
    }

    a.flex-prev{
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left:  -18px;
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}
.sh-titlebx{
    text-align: center;
    h2{
      
        span{
            position: relative;
            @include fontset(28,2,5.5,500);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to bottom,#bebebe 20%, #000000,#d2d2d2);
            @media (max-width:767px) {
                font-size: pxToEm(18);
                letter-spacing: 4px;
                background-image: linear-gradient(180deg,#bebebe 10%,#000 70%,#d2d2d2);
            }
            &::before{
                position: absolute;
                content: '';
                left: -103px;
                top: 50%;
                transform: translateY(-50%);
                background-image: linear-gradient(to bottom,#bebebe 20%, #000000,#d2d2d2);
                width: 80px;
                height: 2px;
                @media (max-width:767px) {
                    width: 50px;
                    left: -65px;
                    height: 3px;
                }
            }
            &::after{
                position: absolute;
                content: '';
                right: -103px;
                top: 50%;
                transform: translateY(-50%);
                background-image: linear-gradient(to bottom,#bebebe 20%, #000000,#d2d2d2);
                width: 80px;
                height: 2px;
                @media (max-width:767px) {
                    width: 50px;
                    right: -61px;
                    height: 3px;
                }
            }
        }
    }
   
}