/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 108px;
    padding: 0 75px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: pxToEm(14);
    padding-left: 0;
    // background: url(dist/images/bavv.jpg) top left no-repeat;
    @media (max-width:1250px) {
        padding-right: 25px;
    }
    @media (max-width:991px) {
        padding-right: 0;
    }
}



.logo {
    width: 528px;
    height: 108px;
    // background: url(../images/logo_pc.png) center center no-repeat /cover;
    background: url(../images/logo_pc.png) center center no-repeat;
    background-size: contain;
    text-indent: -10000px;
    a {
        @include aLink();
    }
    @media (max-width:1250px) {
        width: 440px;
        height: 91px;
    }
    @media (max-width:991px) {
        width: 100vw;
        height: 91px;
    }
}
.pc.logo{
    display: none;
    @media (min-width:992px) {
        display: block;
    }
}
.mo.h-logo{
    display: block;
    width: 100%;
    padding-bottom: 20%;
    background: url(../images/logo_mo.jpg) center center no-repeat;
    background-size: cover;
    @media (min-width:992px) {
        display: none;
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
    @media (max-width:991px) {
        width: 100%;
    }
    ul {
        display: flex;
        li{
            position: relative;
            text-align: center;
            // width: 80px;
            &:first-child{
                a:before{
                    width: 0;
                }
            }
            @media (max-width:991px) {
                width: 25%;
            }
        }
        li:not(:last-child){
            margin-right: 76px;
            @media (max-width:1250px) {
                margin-right: 30px;
            }
            @media (max-width:991px) {
                margin-right: 0;
            }
        }
    }
    a{
        width: 100%;
        display: block;
        color: $white;
        font-size: pxToEm(22);
        position: relative;
        font-weight: 300;
        &::before{
            transition: .4s;
            position: absolute;
            content:'';
            width: 1px;
            height: 22px;
            background: #ff7401;
            top: -1px;
            left: -40px;
            transform: rotate(25deg);
            @media (max-width:1250px) {
                left: -16px;
            }
            @media (max-width:991px) {
                left: 0;
                height: 17px;
                top: 0;
            }
        }
        @media (max-width:991px) {
            font-size: pxToEm(15);
        }
       
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 31px;
$bar-height: 2px;
$bar-spacing: 10px;
$bar-color:#fff;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




